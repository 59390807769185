.time {
  margin: 0;
  font-size: 20vw;
}

.period {
  display: block;
}

@media screen and (min-width: 576px) {
  .period {
    display: inline;
  }
}

.unit {
  color: var(--grey-dark);
  margin-right: 0.1em;
}

.unit:last-child {
  margin-right: 0em;
}
