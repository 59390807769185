.form {
  margin-bottom: 1.5rem;
}

.baseUrl {
  color: var(--grey-dark);
  user-select: none;
}

.input {
  background: transparent;
  border: none;
  border-bottom: dashed 2px var(--grey-dark);
  font-size: 1em;
  text-align: center;
  outline: none;
}

.input.darkTheme {
  color: var(--white);
}

.input.darkTheme:focus {
  border-bottom: dashed 2px var(--white);
}

.input.lightTheme {
  color: var(--black);
}

.input.lightTheme:focus {
  border-bottom: dashed 2px var(--black);
}

.button {
  margin-top: 1.5rem;
}

@media screen and (min-width: 576px) {
  .button {
    margin-top: 2rem;
  }
}
