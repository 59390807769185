.wrapper {
  position: absolute;
  bottom: 2rem;
}

.button {
  margin-left: 1rem;
}

.button:first-child {
  margin-left: 0;
}

.presetButtons {
  display: none;
}

@media screen and (min-width: 576px) {
  .presetButtons {
    display: inline-block;
  }
}
