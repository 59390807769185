:root {
  --black: rgb(40, 44, 52);
  --white: rgb(255, 255, 255);
  --grey-dark: rgb(133, 139, 150);
  --grey: rgb(190, 190, 199);
  --grey-light: rgb(214, 215, 219);
  --grey-lighter: rgb(228, 231, 236);
  --overlay: rgba(10, 10, 10, 0.86);
  --size-1: calc(14px + 4vmin);
  --size-2: calc(14px + 2vmin);
  --size-3: calc(14px + 1vmin);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  font-size: var(--size-3);
  padding: 0.5rem;
}
