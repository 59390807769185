.form {
  display: flex;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: var(--grey-dark);
  border-radius: 5px;
}

.input {
  flex: 1;
  border: none;
  border-radius: 5px;
  outline: none;
}

.button {
  display: none;
}

@media screen and (min-width: 576px) {
  .button {
    display: block;
    margin-left: 1rem;
  }
}
